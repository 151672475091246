import { ILead, LeadStatus } from '@/types';
import { FC, useState } from 'react';
import { LeadStatusBadge } from '../LeadStatusBadge/LeadStatusBadge';
import { CheckIcon } from '@/components/Icons/CheckIcon';
import { WorkIcon } from '@/components/Icons/WorkIcon';
import { CloseIcon } from '@/components/Icons/CloseIcon';
import { useAppDispatch } from '@/hooks/useRedux';
import {
  setErrorOpen,
  setId,
  setSuccessOpen,
  setWorkOpen,
} from '@/redux/features/leadsModalsSlice';

type Props = {
  data: ILead;
};

export const LeadsMobCollapse: FC<Props> = ({ data }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  return (
    <div
      className={`mob-collapse bg-base-100 theme-border last:border-b-0 collapse ${
        isOpen ? 'collapse-open' : ''
      }`}
    >
      <div
        className='collapse-title cursor-pointer min-h-[auto] py-2 px-2 min-[360px]:px-5'
        onClick={() => setIsOpen((p) => !p)}
      >
        <div className='flex gap-2 items-center flex-wrap justify-between'>
          <div className='mr-auto text-sm'>
            {new Date(data.createdAt)
              .toLocaleDateString(navigator.language, {
                hour: '2-digit',
                minute: '2-digit',
              })
              .replace(',', '')}
          </div>
          <LeadStatusBadge
            comment={data.comment}
            status={data.status}
            size='badge-sm'
          />
        </div>
      </div>

      <div className='collapse-content px-2 min-[360px]:px-5'>
        <div>
          <hr className={`divider h-[1px] ${isOpen && 'mt-0'}`} />
          <div className='flex flex-col items-start gap-1'>
            <div className='flex items-center gap-2 flex-wrap'>
              <label className='label p-0'>
                <span className='label-text font-medium'>ID:</span>
              </label>
              <div>{data.id}</div>
            </div>

            <div className='flex items-center gap-2 flex-wrap'>
              <label className='label p-0'>
                <span className='label-text font-medium'>Дата:</span>
              </label>
              <div>
                {new Date(data.createdAt)
                  .toLocaleDateString(navigator.language, {
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                  .replace(',', '')}
              </div>
            </div>

            <div className='flex items-center gap-2 flex-wrap'>
              <label className='label p-0'>
                <span className='label-text font-medium'>AMO ID:</span>
              </label>
              <div>{data.amoId}</div>
            </div>

            <div className='flex items-center gap-2 flex-wrap'>
              <label className='label p-0'>
                <span className='label-text font-medium'>Вебмастер:</span>
              </label>
              <div>
                <div className='tooltip' data-tip={`ID: ${data.webmasterId}`}>
                  {data.webmasterLogin}
                </div>
              </div>
            </div>

            <div className='flex items-center gap-2 flex-wrap'>
              <label className='label p-0'>
                <span className='label-text font-medium'>Категория:</span>
              </label>
              <div>{data.categoryName ? data.categoryName : '----'}</div>
            </div>

            <div className='flex items-center gap-2 flex-wrap'>
              <label className='label p-0'>
                <span className='label-text font-medium'>Город:</span>
              </label>
              <div>{data.cityName ? data.cityName : '----'}</div>
            </div>

            <div className='flex items-center gap-2 flex-wrap'>
              <label className='label p-0'>
                <span className='label-text font-medium'>Адрес:</span>
              </label>
              <div>{data.address ? data.address : '----'}</div>
            </div>

            <div className='flex items-center gap-2 flex-wrap'>
              <label className='label p-0'>
                <span className='label-text font-medium'>Тел. клиента:</span>
              </label>
              <div>{data.leadPhone}</div>
            </div>

            <div className='flex items-center gap-2 flex-wrap'>
              <label className='label p-0'>
                <span className='label-text font-medium'>Телефония:</span>
              </label>
              <div>
                {data.trackingPhone === 'p_manually'
                  ? 'Вручную (Частное лицо)'
                  : data.trackingPhone === 'c_manually'
                  ? 'Вручную (Компания)'
                  : data.trackingPhone === 'c_api'
                  ? 'API (Компания)'
                  : data.trackingPhone === 'p_api'
                  ? 'API (Частное лицо)'
                  : data.trackingPhone}
              </div>
            </div>

            <div className='flex items-center gap-2 flex-wrap'>
              <label className='label p-0'>
                <span className='label-text font-medium'>Выплата:</span>
              </label>
              <div>
                {data.sum
                  ? data.status === LeadStatus.APPROVED ||
                    data.status === LeadStatus.IN_WORK
                    ? data.sum + ' K'
                    : '----'
                  : '----'}
              </div>
            </div>

            <div className='flex items-center gap-2 flex-wrap'>
              <label className='label p-0'>
                <span className='label-text font-medium'>Cтатус:</span>
              </label>
              <div>
                <LeadStatusBadge
                  comment={data.comment}
                  status={data.status}
                  size='badge-sm'
                />
              </div>
            </div>
          </div>

          <div className='flex gap-2 mt-5'>
            <button
              className='btn btn-xs btn-success lowercase'
              onClick={() => {
                dispatch(setId(data.id));
                dispatch(setSuccessOpen(true));
              }}
              disabled={
                data.status === LeadStatus.APPROVED ||
                data.status === LeadStatus.REJECTED
                  ? true
                  : false
              }
            >
              <CheckIcon />
            </button>

            <button
              className='btn btn-xs btn-primary lowercase'
              onClick={() => {
                dispatch(setId(data.id));
                dispatch(setWorkOpen(true));
              }}
              disabled={
                data.status === LeadStatus.APPROVED ||
                data.status === LeadStatus.REJECTED
                  ? true
                  : data.status === LeadStatus.IN_WORK
                  ? true
                  : false
              }
            >
              <WorkIcon />
            </button>

            <button
              className='btn btn-xs btn-error lowercase'
              onClick={() => {
                dispatch(setId(data.id));
                dispatch(setErrorOpen(true));
              }}
              disabled={
                data.status === LeadStatus.APPROVED ||
                data.status === LeadStatus.REJECTED
                  ? true
                  : false
              }
            >
              <CloseIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
