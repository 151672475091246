import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { tokenLSKey } from '@/constants';
import { LegalInformation, LegalInformationStatus } from '@/types';

export const legalInformationAPI = createApi({
  reducerPath: 'legalInformationAPI',

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + '/legal-information/',
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(tokenLSKey);

      if (token) headers.set('authorization', `Bearer ${token}`);
      return headers;
    },
  }),

  tagTypes: ['LegalInformation'],
  endpoints: (builder) => ({
    getLegalInformation: builder.query<
      LegalInformation,
      { userId: number | null; role: 'webmaster' | 'curator' | null }
    >({
      query: (params) => ({
        url: '',
        method: 'GET',
        params,
      }),
      providesTags: (result) => ['LegalInformation'],
    }),
    changeStatus: builder.mutation<
      LegalInformation,
      {
        userId: number;
        role: 'webmaster' | 'curator';
        status: LegalInformationStatus;
      }
    >({
      query: (body) => ({
        url: '/status',
        method: 'PATCH',
        body,
      }),
    }),
  }),
});

export const { useGetLegalInformationQuery, useChangeStatusMutation } =
  legalInformationAPI;
