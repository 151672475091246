import { IAdmin, IAdminState, RootState } from "@/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: IAdminState = {
  id: null,
  login: null,
  isAuth: false,
};

export const adminSlice = createSlice({
  initialState,
  name: "admin",
  reducers: {
    logout: (state) => initialState,
    setAdmin: (state, action: PayloadAction<IAdmin>) => {
      state.id = action.payload.id;
      state.login = action.payload.login;
      state.isAuth = true;
    },
  },
});

export default adminSlice.reducer;

export const { logout, setAdmin } = adminSlice.actions;
export const admin = (state: RootState) => state.admin;
