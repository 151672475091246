import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { tokenLSKey } from "@/constants";
import { ICity } from "@/types";

export const citiesAPI = createApi({
  reducerPath: "citiesAPI",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/cities/",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(tokenLSKey);

      if (token) headers.set("authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  tagTypes: ["Cities"],
  endpoints: (builder) => ({
    getAllСities: builder.query<ICity[], null>({
      query: () => ({
        url: "",
        method: "GET",
      }),
      providesTags: (result) => ["Cities"],
    }),
    addCity: builder.mutation<ICity, { name: string }>({
      query: (body) => ({
        url: "",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Cities"],
    }),
    removeCity: builder.mutation<{ message: string }, { id: number }>({
      query: (body) => ({
        url: "",
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Cities"],
    }),
  }),
});

export const {
  useGetAllСitiesQuery,
  useAddCityMutation,
  useRemoveCityMutation,
} = citiesAPI;
