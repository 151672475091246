import { LeadStatus, LossReasonText } from "@/types";
import { useState } from "react";

type Props = {
  valueHandler: (status: string) => void;
};

const statuses = (
  Object.keys(LeadStatus) as Array<keyof typeof LeadStatus>
).map((key) => LeadStatus[key]);

const lossReasones = (
  Object.keys(LossReasonText) as Array<keyof typeof LossReasonText>
).map((key) => LossReasonText[key]);

const statusText = (status: LeadStatus) => {
  switch (status) {
    case LeadStatus.LEAD:
      return "В обработке";
    case LeadStatus.IN_WORK:
      return "В работе";
    case LeadStatus.APPROVED:
      return "Подтвержден";
    case LeadStatus.REJECTED:
      return "Все отклоненные";
  }
};

export const SelectStatus: React.FC<Props> = ({ valueHandler }) => {
  const [selected, setSelected] = useState<string>();

  const changeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const status = event.target.value;
    setSelected(status);
    valueHandler(status);
  };

  return (
    <>
      <label className="label">
        <span className="label-text">Статус</span>
      </label>
      <select
        className="select select-sm select-bordered w-full lg:max-w-xs "
        value={selected}
        onChange={changeSelect}
      >
        <option value="all">Все Статусы </option>
        {statuses.map((status) => (
          <option value={status} key={status}>
            {statusText(status)}
          </option>
        ))}
        {lossReasones.map((reason) => (
          <option value={`${LeadStatus.REJECTED}|${reason}`} key={reason}>
            {reason}
          </option>
        ))}
      </select>
    </>
  );
};
