import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { useInWorkManuallyMutation } from "@/redux/api/leadsAPI";
import { leadsModals, setWorkOpen } from "@/redux/features/leadsModalsSlice";
import { IErrorResponse } from "@/types";
import { useEffect } from "react";
import { NotificationManager as notify } from "react-notifications";

export const LeadsInWorkModal = () => {
  const isOpen = useAppSelector(leadsModals).isInWorkOpen;
  const id = useAppSelector(leadsModals).id;
  const dispatch = useAppDispatch();

  const [inWorkManually, { data, error }] = useInWorkManuallyMutation();

  useEffect(() => {
    if (!error) return;
    const { data } = error as IErrorResponse;
    if (data) return notify.error(data.message);
    return notify.error("Что-то пошло не так");
  }, [error]);

  useEffect(() => {
    if (!data) return;

    dispatch(setWorkOpen(false));

    notify.success("Успешно отправлено в работу!");
  }, [data, dispatch]);

  return (
    <div className={`modal ${isOpen ? "modal-open" : ""} `}>
      <div className="modal-box  w-11/12  max-w-sm relative">
        <h3 className="font-bold text-lg md:text-2xl mb-5">
          Отправка лида в работу
        </h3>

        <div className="modal-action">
          <button
            className="btn btn-sm btn-error w-1/2"
            onClick={() => dispatch(setWorkOpen(false))}
          >
            Отмена
          </button>
          <button
            className="btn btn-sm btn-success w-1/2"
            onClick={() => {
              if (id) {
                inWorkManually({ id });
              }
            }}
          >
            В работу
          </button>
        </div>
      </div>
    </div>
  );
};
