import { IWebmaster } from "@/types";
import { FC, useState } from "react";
import { TelephonyCeil } from "../TelephonyCeil/TelephonyCeil";
import { setEditWebmasterAndOpen } from "@/redux/features/editWebmasterModalSlice";
import { PencilIcon } from "@/components/Icons/PencilIcon";
import { BanUserButton } from "@/components/BanUserButton/BanUserButton";
import { setRemoveUserAndOpenModal } from "@/redux/features/removeUserModalSlice";
import { TrashIcon } from "@/components/Icons/TrashIcon";
import { useAppDispatch } from "@/hooks/useRedux";
import {
  LegalInforamitionButton,
  // LegalInformationStatusBadge,
} from "@/components";

type Props = {
  data: IWebmaster;
};

export const WebmastersMobCollapse: FC<Props> = ({ data }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  return (
    <div
      className={`mob-collapse bg-base-100 theme-border last:border-b-0 inner-collapse collapse-arrow ${
        isOpen ? "collapse-open" : ""
      }`}
    >
      <div
        className="collapse-title cursor-pointer min-h-[auto] py-2 px-2 min-[360px]:px-5"
        onClick={(e) => setIsOpen((p) => !p)}
      >
        <div className="flex gap-2 items-center flex-wrap justify-between">
          <div className="mr-auto text-sm">{data.login}</div>
        </div>
      </div>

      <div className="collapse-content px-2 min-[360px]:px-5">
        <div>
          <hr className={`divider h-[1px] ${isOpen && "mt-0"}`} />
          <div className="flex flex-col items-start gap-1">
            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">ID:</span>
              </label>
              <div>{data.id}</div>
            </div>

            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Логин:</span>
              </label>
              <div>{data.login}</div>
            </div>
            {/* 
            <div className='flex items-center gap-2 flex-wrap'>
              <label className='label p-0'>
                <span className='label-text font-medium'>юр. статус:</span>
              </label>

              <LegalInformationStatusBadge
                status={data.legalInformation?.status}
              />
            </div> */}

            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Добавлен:</span>
              </label>
              <div>{new Date(data.createdAt).toLocaleDateString()}</div>
            </div>

            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Рекл. компании:</span>
              </label>
              <div className={`${data.telephony.length > 0 ? "w-full" : ""}`}>
                {data.telephony.length
                  ? data.telephony.map((t) => (
                      <TelephonyCeil
                        key={t.number}
                        name={t.name}
                        number={t.number}
                        type={t.type}
                      />
                    ))
                  : "---"}
              </div>
            </div>

            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Баланс:</span>
              </label>
              <div>{data.balance.balance} K</div>
            </div>

            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Холд:</span>
              </label>
              <div>{data.balance.hold} K</div>
            </div>

            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Заработано:</span>
              </label>
              <div>{data.balance.totalEarned} K</div>
            </div>

            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Телеграм:</span>
              </label>
              <div>
                {data.workTelegram ? (
                  <a
                    className="link link-primary"
                    target="_blank"
                    href={`https://t.me/${data.workTelegram}`}
                    rel="noreferrer"
                  >
                    @{data.workTelegram}
                  </a>
                ) : (
                  "---"
                )}
              </div>
            </div>

            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Телефон:</span>
              </label>
              <div>{data.workPhone || "---"}</div>
            </div>

            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Куратор:</span>
              </label>
              <div>
                {data.curator ? (
                  <span className="badge badge-success">
                    {data.curator.name}
                  </span>
                ) : (
                  "---"
                )}
              </div>
            </div>

            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Реферрер:</span>
              </label>
              <div>
                {data.refName && data.refId ? (
                  <div className="tooltip" data-tip={`ID: ${data.refId}`}>
                    {data.refName}
                  </div>
                ) : (
                  "---"
                )}
              </div>
            </div>
          </div>

          <div className="flex gap-2 mt-5">
            <LegalInforamitionButton
              status={data.legalInformation?.status}
              role="webmaster"
              userId={data.id}
            />
            <div className="tooltip" data-tip="редактировать">
              <button
                className="btn btn-primary btn-xs capitalize"
                onClick={() => dispatch(setEditWebmasterAndOpen(data))}
              >
                <PencilIcon />
              </button>
            </div>
            <BanUserButton
              id={data.id}
              isBanned={data.isBanned}
              role="webmaster"
            />
            <div className="tooltip" data-tip="удалить">
              <button
                className="btn btn-error btn-xs capitalize"
                onClick={() =>
                  dispatch(
                    setRemoveUserAndOpenModal({
                      id: data.id,
                      login: data.login,
                      role: "webmaster",
                    })
                  )
                }
              >
                <TrashIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
