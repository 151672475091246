import { rootReducer, setupStore } from "@/redux/store";
import { OutputData } from "@editorjs/editorjs";

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];

export interface IAppState {
  theme: "winter" | "halloween";
  addWebmasterModalIsOpen: boolean;
  addCuratorModalIsOpen: boolean;
  addCityModalIsOpen: boolean;
  addOfferCategoryModalIsOpen: boolean;
  mainDrawerIsOpen: boolean;
}

export interface INavigationState {
  newsOffset: number;
  newsLimit: number;
  newsTotal: number | null;
  offersOffset: number;
  currentNewsPage: number;
}

export interface IAccessToken {
  accessToken: string;
}

export interface ILoginData {
  login: string;
  password: string;
}

export interface IErrorResponse {
  data: { message: string };
}

export interface IRemoveDataResponse {
  data: { message: string };
}

export interface LegalInformation {
  id: number;
  name: string;
  bankBic: string;
  INN: string;
  checkingAccount: string;
  address: string;
  webmasterId?: number;
  curatorId?: number;
  status: LegalInformationStatus;
}

export enum LegalInformationStatus {
  CONFIRMED = "confirmed",
  REJECTED = "rejected",
  MODERATION = "moderation",
}

export interface IAdminState {
  id: number | null;
  login: string | null;
  isAuth: boolean;
}

export interface IUser {
  id: number;
  login: string;
  roles: string[];
  createdAt: Date;
  updatedAt: Date;
  legalInformation: null | Pick<LegalInformation, "status">;
}

export interface IAdmin extends IUser {}

export interface ICurator extends IUser {
  name: string;
  workPhone: string | null;
  workTelegram: string | null;
  bankCards: string[];
  isBanned: boolean;
  balance: ICuratorBalance;
  webmasters: { id: number }[];
}

export interface IWebmaster extends IUser {
  telephony: ITelephony[];
  workPhone: string | null;
  workTelegram: string | null;
  bankCards: string[];
  isBanned: boolean;
  balance: IWebmasterBalance;
  refId: number | null;
  refName: string | null;
  curatorId: number | null;
  curator: Pick<ICurator, "name" | "login"> | null;
}

export interface IAddWebmaster
  extends Pick<
    IWebmaster,
    "login" | "telephony" | "workPhone" | "workTelegram" | "curatorId"
  > {
  password: string;
}

export interface IAddCurator
  extends Pick<ICurator, "login" | "workPhone" | "workTelegram" | "name"> {
  password: string;
}

export interface IEditWebmaster
  extends Pick<
    IWebmaster,
    "login" | "telephony" | "id" | "workPhone" | "workTelegram" | "curatorId"
  > {
  password: string | null;
}

export interface IEditCurator
  extends Pick<
    ICurator,
    "login" | "name" | "id" | "workPhone" | "workTelegram"
  > {
  password: string | null;
}

export enum TelephonyType {
  PRIVATE = "private",
  COMPANY = "company",
}

export interface ITelephonyInputs {
  name: string;
  number: string;
  type: TelephonyType | "none";
}

export interface ITelephony {
  name: string;
  number: string;
  type: TelephonyType;
}

export interface ICuratorBalance {
  id: number;
  balance: number;
  totalEarned: number;
}

export interface IWebmasterBalance extends ICuratorBalance {
  hold: number;
}

export interface IWebmastersResponse {
  total: number;
  offset: null | number;
  limit: null | number;
  webmasters: IWebmaster[];
}

export interface IGetWebmastersQuery {
  offset: number;
  limit: number;
  sort: UsersSortType | undefined;
}

export interface ICuratorsResponse {
  total: number;
  offset: null | number;
  limit: null | number;
  curators: ICurator[];
}

export interface IGetCuratorsQuery {
  offset: number;
  limit: number;
  sort: UsersSortType | undefined;
}

export enum UsersSortType {
  DATE_UP = "date-up",
  DATE_DOWN = "date-down",
  SUM_UP = "sum-up",
  SUM_DOWN = "sum-down",
}

export interface IConfirmWebmaster
  extends Pick<
    IWebmaster,
    "login" | "telephony" | "workPhone" | "workTelegram" | "refId" | "curatorId"
  > {
  password: string;
  requestId: number;
}

export interface IEditWebmasterModalState {
  webmaster: null | IWebmaster;
  isOpen: boolean;
}

export interface ILegalInformationModalState {
  role: null | "webmaster" | "curator";
  isOpen: boolean;
  userId: number | null;
}

export interface IEditCuratorModalState {
  curator: null | ICurator;
  isOpen: boolean;
}

export interface IConfirmCandidateModalState {
  candidate: null | IRegisterRequest;
  isOpen: boolean;
}

export interface IRemoveWebmasterModalState {
  id: null | number;
  login: null | string;
  isOpen: boolean;
  role: "webmaster" | "curator" | null;
}

export interface IBanWebmasterModalState {
  id: null | number;
  login: null | string;
  isOpen: boolean;
}

export interface IPaymentModalState {
  isOpen: boolean;
  data: IPayment | null;
}
export interface IEditorCore {
  destroy(): Promise<void>;

  clear(): Promise<void>;

  save(): Promise<OutputData>;

  render(data: OutputData): Promise<void>;
}

export interface INews {
  id: number;
  title: string;
  visible: boolean;
  createdAt: Date;
  updatedAt: Date;
  content: string;
}

export interface INewsPrewiew extends Omit<INews, "content"> {}

export interface INewsResponse {
  news: INewsPrewiew[];
  total: number;
  offset: number;
  limit: number;
}

export interface IAddNews
  extends Omit<INews, "updatedAt" | "createdAt" | "id"> {}

export interface IEditNews extends Omit<INews, "updatedAt" | "createdAt"> {}

export interface IRemoveCityModalData {
  isOpen: boolean;
  id: number | null;
  name: string | null;
}

export interface IRemoveCategoryModalData {
  isOpen: boolean;
  id: number | null;
  name: string | null;
}

export interface IRemoveNewsModalData {
  isOpen: boolean;
  id: number | null;
  title: string | null;
}

export interface IRemoveOfferModalData {
  isOpen: boolean;
  id: number | null;
  name: string | null;
}

export interface ICity {
  id: number;
  name: string;
}

export interface IOfferCategory {
  id: number;
  name: string;
  img: string;
}

export interface ICitiesTableData extends ICity {
  offers: number;
}

export interface ICategoriesTableData extends IOfferCategory {
  offers: number;
}

export interface IOffersResponse {
  total: number;
  totalWithParams: number | null;
  offset: null | number;
  limit: null | number;
  categoryId: null | number;
  cityId: null | number;
  offers: IOffer[];
}

export interface IOffer {
  id: number;
  name: string;
  leadPrice: null | number;
  companyLeadPrice: null | number;
  isPaused: boolean;
  cityId: number;
  categoryId: number;
  createdAt: Date;
  updatedAt: Date;
  city: ICity;
  category: IOfferCategory;
}

export interface IOfferWithContent extends IOffer {
  content: string;
}

export interface IAddOffer
  extends Omit<
    IOfferWithContent,
    "createdAt" | "updatedAt" | "city" | "category" | "id"
  > {}

export interface IEditOffer
  extends Omit<
    IOfferWithContent,
    "createdAt" | "updatedAt" | "city" | "category"
  > {}

export interface IStatsResponse {
  webmastersTotalEarned: number;
  webmastersLeadTotalEarned: number;
  webmastersRefsTotalEarned: number;
  webmastersTotalPaid: number;
  curatorsTotalEarned: number;
  totalEarned: number;
  totalPaid: number;
  curatorsTotalPaid: number;
  waitingPayments: {
    webmasters: number;
    curators: number;
    webmastersSum: number;
    curatorsSum: number;
  };
}

export interface IPayment {
  id: number;
  sum: number;
  bankCard: string;
  bankCardName: string;
  fio: string;
  isCompleted: false;
  webmasterId: number | null;
  curatorId: number | null;
  login: string;
  name: string | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface IWebmasterPayment extends IPayment {
  webmasterId: number;
}
export interface ICuratorPayment extends IPayment {
  curatorId: number;
  name: string;
}
export interface IHistoryPayment extends IPayment {
  curatorId: number | null;
  name: string | null;
  webmasterId: number | null;
}

export interface IPaymentsResponse {
  count: number;
  rows: IPayment[];
}

export function isWebmaserPayment(object: any): object is IWebmasterPayment {
  return object.webmasterId ? true : false;
}

//======LEADS=========//

export enum LeadStatus {
  LEAD = "lead",
  IN_WORK = "in_work",
  APPROVED = "approved",
  REJECTED = "rejected",
}

export interface ILead {
  id: number;
  amoId: string;
  status: LeadStatus;
  webmasterId: number | null;
  webmasterLogin: string | null;
  offerId: number | null;
  cityId: number | null;
  categoryId: number | null;
  sum: number | null;
  date: string | Date | null;
  comment: string | null;
  address: string | null;
  trackingPhone: string;
  hiddenLeadPhone: string;
  leadPhone: string;
  cityName: string | null;
  categoryName: string | null;
  data: JSON | null;
  createdAt: Date;
}

export interface ILeadsResponse {
  total: number;
  totalWithParams: number | null;
  offset: null | number;
  limit: null | number;
  categoryId: null | number;
  cityId: null | number;
  leads: ILead[];
  status: LeadStatus | null;
  sum: number | null;
}
export interface ILeadsState {
  startDate: string | null;
  endDate: string | null;
}

export enum LossReasonText {
  NOT_VALID = "Невалид",
  INSOLVENT_PERSON = "Неплатёжеспособное лицо",
  LEAD_CANCEL = "Отмена лида",
  NOT_CONFIRM = "Не подтверждён",
  NOT_DIALING = "Пропала связь с клиентом",
  SPAM = "Спам",
  NOT_ACTIVE_OFFER = "Неактив. оффер",
  NOT_ACTIVE_GEO = "Неактив. ГЕО",
  CALL_CHECK = "Проверка связи",
}
export interface IRegisterRequest {
  login: string;
  password: string;
  workPhone: string | null;
  workTelegram: string | null;
  refId: number | null;
  refName: string | null;
  curatorId: number | null;
  curatorName: string | null;
  id: number;
  updatedAt: Date;
  createdAt: Date;
}
export interface IRegisterRequestsResponse {
  count: number;
  rows: IRegisterRequest[];
}

export enum PaymentsSortType {
  DATE_UP = "date-up",
  DATE_DOWN = "date-down",
  SUM_UP = "sum-up",
  SUM_DOWN = "sum-down",
}

export type FinancesSearchType =
  | "completed"
  | "not-completed-webmasters"
  | "not-completed-curators";
