import { routing } from "@/constants";
import useOnClickOutside from "@/hooks/useOnClickOutside";
import { MutableRefObject, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { BriefcaseIcon } from "../Icons/BriefcaseIcon";

import {
  WalletIcon,
  // UserPlusIcon,
  BurgerIcon,
  DiamondIcon,
  UsersIcon,
  NewsIcon,
  ReportMoneyIcon,
} from "@/components";

export const MobileMenu = () => {
  const ref: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const [open, setOpen] = useState(false);
  useOnClickOutside(ref, () => setOpen(false));

  return (
    <div className="navbar-start lg:hidden">
      <div ref={ref} className={`dropdown  ${open ? "dropdown-open" : ""}`}>
        <label
          tabIndex={0}
          className="btn btn-ghost "
          onClick={() => setOpen(!open)}
        >
          <BurgerIcon />
        </label>
        <ul
          tabIndex={0}
          className="menu menu-compact dropdown-content dropdown-open mt-3 p-2 shadow bg-base-100 rounded-box w-52"
        >
          <li onClick={() => setOpen(false)}>
            <Link to={routing.WEBMASTERS}>
              <UsersIcon />
              <span>Вебмастера</span>
            </Link>
          </li>
          {/* <li onClick={() => setOpen(false)}>
            <Link to={routing.CURATORS}>
              <UserPlusIcon />
              <span>Кураторы</span>
            </Link>
          </li> */}
          <li onClick={() => setOpen(false)}>
            <Link to={routing.LEADS}>
              <ReportMoneyIcon />
              <span>Лиды</span>
            </Link>
          </li>
          <li onClick={() => setOpen(false)}>
            <Link to={routing.PAYMENTS}>
              <WalletIcon />
              <span>Выплаты</span>
            </Link>
          </li>

          <li onClick={() => setOpen(false)}>
            <Link to={routing.NEWS}>
              <NewsIcon />
              <span>Новости</span>
            </Link>
          </li>

          <li onClick={() => setOpen(false)}>
            <Link to={routing.OFFERS}>
              <DiamondIcon />
              <span>Офферы</span>
            </Link>
          </li>

          <li onClick={() => setOpen(false)}>
            <Link to={routing.CATS_AND_CITIES}>
              <BriefcaseIcon />
              <span>Категории и города</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
