export const Arrow = () => {
  return (
    <span className="arrow">
      <svg
        width="16"
        height="16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.361.238a.977.977 0 011.278 0l7.097 6.377a.755.755 0 010 1.147.977.977 0 01-1.278 0L8 1.96 1.542 7.762a.977.977 0 01-1.277 0 .755.755 0 010-1.147L7.36.238z"
          fill=""
        />
        <rect
          width="2"
          height="16"
          rx="1"
          transform="matrix(-1 0 0 1 9 0)"
          fill=""
        />
      </svg>
    </span>
  );
};
