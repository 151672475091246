import { IAppState, RootState } from "@/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const drawerState = localStorage.getItem("drawer");
const initialState: IAppState = {
  theme: "winter",
  addWebmasterModalIsOpen: false,
  addCuratorModalIsOpen: false,
  addCityModalIsOpen: false,
  addOfferCategoryModalIsOpen: false,
  mainDrawerIsOpen: drawerState ? Boolean(parseInt(drawerState)) : true,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    changeTheme: (state, action: PayloadAction<"winter" | "halloween">) => {
      state.theme = action.payload;
    },
    toggleAddWebmasterModal: (state) => {
      state.addWebmasterModalIsOpen = !state.addWebmasterModalIsOpen;
    },
    toggleAddCuratorModal: (state) => {
      state.addCuratorModalIsOpen = !state.addCuratorModalIsOpen;
    },
    toggleAddCityModal: (state) => {
      state.addCityModalIsOpen = !state.addCityModalIsOpen;
    },
    toggleAddOfferCategoryModal: (state) => {
      state.addOfferCategoryModalIsOpen = !state.addOfferCategoryModalIsOpen;
    },
    toggleMainDrawer: (state) => {
      state.mainDrawerIsOpen = !state.mainDrawerIsOpen;
      localStorage.setItem("drawer", state.mainDrawerIsOpen ? "1" : "0");
    },
  },
});

export default appSlice.reducer;
export const {
  changeTheme,
  toggleAddWebmasterModal,
  toggleAddCuratorModal,
  toggleAddCityModal,
  toggleAddOfferCategoryModal,
  toggleMainDrawer,
} = appSlice.actions;
export const app = (state: RootState) => state.app;
