import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { app, changeTheme } from "@/redux/features/appSlice";
import { useEffect } from "react";
import { MoonIcon } from "../Icons/MoonIcon";
import { SunIcon } from "../Icons/SunIcon";

export const ThemeSwitch = () => {
  const { theme } = useAppSelector(app);
  const dispatch = useAppDispatch();

  const switchThemeHandler = () => {
    dispatch(changeTheme(theme === "winter" ? "halloween" : "winter"));
  };

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  return (
    <div className="cursor-pointer w-7 noselect" onClick={switchThemeHandler}>
      {theme === "halloween" ? <MoonIcon /> : <SunIcon />}
    </div>
  );
};
