import { LegalInformationStatus } from '@/types';
import { InfoCircle } from '../Icons/InfoCircle';
import { useAppDispatch } from '@/hooks/useRedux';
import { setUserIdAndRoleAndOpen } from '@/redux/features/legalInformationModalSlice';

type Props = {
  status: LegalInformationStatus | undefined;
  role: 'webmaster' | 'curator';
  userId: number;
};

export const LegalInforamitionButton: React.FC<Props> = ({
  status,
  role,
  userId,
}) => {
  const dispatch = useAppDispatch();

  const toolTipText = status ? 'юр. информация' : 'данных нет';

  return (
    <span className='tooltip' data-tip={toolTipText}>
      <button
        onClick={() => dispatch(setUserIdAndRoleAndOpen({ role, userId }))}
        disabled={!!!status}
        className='btn btn-info btn-xs'
      >
        <InfoCircle />
      </button>
    </span>
  );
};
