import { useEffect } from 'react';
import { NotificationManager as notify } from 'react-notifications';

import { IErrorResponse, isWebmaserPayment } from '@/types';
import { useSetComletedMutation } from '@/redux/api/paymentsAPI';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import {
  clearPaymentDataAndClose,
  paymentModal,
} from '@/redux/features/paymentModalSlice';

export const ConfirmPaymentModal = () => {
  const dispatch = useAppDispatch();

  const [confirm, { isSuccess, error }] = useSetComletedMutation();

  const { data, isOpen } = useAppSelector(paymentModal);

  const confirmHandler = () => {
    if (!data) return;
    if (isWebmaserPayment(data)) {
      const { id: paymentId, sum, webmasterId } = data;
      confirm({ paymentId, sum, webmasterId });
    } else {
      const { id: paymentId, sum, curatorId } = data;
      confirm({ paymentId, sum, curatorId: curatorId || undefined });
    }
  };

  useEffect(() => {
    if (!isSuccess) return;
    notify.success('Выплата подтверждена');
    dispatch(clearPaymentDataAndClose());
    dispatch({
      type: `statsAPI/invalidateTags`,
      payload: ['Stats'],
    });
  }, [isSuccess, dispatch]);

  useEffect(() => {
    if (!error) return;
    const { data } = error as IErrorResponse;
    notify.error(data.message);
  }, [error]);

  return (
    <div className={`modal ${isOpen ? 'modal-open' : ''}`}>
      <div className='modal-box'>
        <h3 className='font-bold text-lg'>Подтверждение выплаты</h3>
        <p className='py-4'>Вы действительно хотите подтвердить выплату ?</p>
        <div className='flex justify-between w-2/3'>
          <div className='flex flex-col'>
            <span>
              {data && isWebmaserPayment(data) ? 'Вебмастер:' : 'Куратор:'}
            </span>
            <span>Сумма:</span>
            <span>Карта:</span>
            <span>Номер карты:</span>
          </div>
          <div className='flex flex-col'>
            <span className='font-medium'>{data?.login}</span>
            <span className='font-medium'>{data?.sum}K</span>
            <span className='font-medium'>{data?.bankCardName}</span>
            <span className='font-medium'>{data?.bankCard}</span>
          </div>
        </div>
        <div className='modal-action'>
          <button className='btn btn-sm btn-primary' onClick={confirmHandler}>
            подтвердить
          </button>
          <button
            className='btn btn-sm btn-error'
            onClick={() => dispatch(clearPaymentDataAndClose())}
          >
            отмена
          </button>
        </div>
      </div>
    </div>
  );
};
