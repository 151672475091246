import { PaymentsSortType } from "@/types";
import { SelectPaymentsDates } from "../SelectPaymentsDates/SelectPaymentsDates";

type IProps = {
  filters: {
    sort?: PaymentsSortType;
    startDate?: string | null;
    endDate?: string | null;
  };

  setFilters: React.Dispatch<
    React.SetStateAction<{
      sort?: PaymentsSortType | undefined;
      startDate?: string | null | undefined;
      endDate?: string | null | undefined;
    }>
  >;

  setOffset: React.Dispatch<React.SetStateAction<number>>;
};

export const PaymentsFilter: React.FC<IProps> = ({
  filters,
  setFilters,
  setOffset,
}) => {
  return (
    <div className="flex items-center gap-5 flex-wrap">
      <div className="bg-base-100 shadow offers-top-panel rounded-md px-6 py-4 flex  gap-5 flex-grow w-full md:w-max">
        <div className="flex md:gap-5 flex-col md:flex-row filters-panel w-full md:items-end flex-wrap">
          <div>
            <label className="label">
              <span className="label-text">Соритровать по</span>
            </label>
            <select
              className="select select-sm select-bordered w-full lg:max-w-xs"
              value={filters.sort === undefined ? "all" : filters.sort}
              onChange={(e) => {
                setOffset(0);

                setFilters((prev) => {
                  return {
                    ...prev,
                    sort:
                      e.target.value === "all"
                        ? undefined
                        : (e.target.value as PaymentsSortType),
                  };
                });
              }}
            >
              <option value={"all"}>Без сортировки</option>
              <option value={PaymentsSortType.DATE_UP}>Дата {`(новые)`}</option>
              <option value={PaymentsSortType.DATE_DOWN}>
                Дата {`(старые)`}
              </option>
              <option value={PaymentsSortType.SUM_UP}>
                Сумма {`(по убыванию)`}
              </option>
              <option value={PaymentsSortType.SUM_DOWN}>
                Сумма {`(по возрастанию)`}
              </option>
            </select>
          </div>
        </div>
      </div>
      <SelectPaymentsDates
        filters={filters}
        setFilters={setFilters}
        setOffset={setOffset}
      />
    </div>
  );
};
