import { Outlet } from "react-router-dom";
import { useEffect } from "react";

import { useGetMeQuery } from "@/redux/api/adminAPI";
import { PageLoader } from "@/components";
import { useAppSelector } from "@/hooks/useRedux";
import { admin } from "@/redux/features/adminSlice";
import { routing, tokenLSKey } from "@/constants";

export const AuthLayout = () => {
  const { isAuth } = useAppSelector(admin);
  const { isLoading, isFetching, error } = useGetMeQuery(null);
  const loading = isLoading || isFetching;

  useEffect(() => {
    if (!error) return;
    localStorage.removeItem(tokenLSKey);
    window.location.href = "/" + routing.LOGIN;
  }, [error]);

  if (loading || !isAuth) return <PageLoader />;
  return <Outlet />;
};
