import { ICurator } from "@/types";
import { useAppDispatch } from "@/hooks/useRedux";

import { setRemoveUserAndOpenModal } from "@/redux/features/removeUserModalSlice";
import {
  BanUserButton,
  EditCuratorModal,
  //LegalInforamitionButton,
  // LegalInformationStatusBadge,
  PencilIcon,
  RemoveUserModal,
  TrashIcon,
} from "@/components";
import { setEditCuratorAndOpenModal } from "@/redux/features/editCuratorModalSlice";

type props = {
  curators: ICurator[];
};

export const CuratorsTable: React.FC<props> = ({ curators }) => {
  const dispatch = useAppDispatch();

  return (
    <div className="overflow-x-auto ">
      <table className="table table-compact w-full">
        <thead>
          <tr>
            <th>ID</th>
            <th>Логин</th>
            {/* <th>юр. статус</th> */}
            <th>Имя</th>
            <th>Добавлен</th>
            <th>Баланс</th>
            <th>Заработано</th>
            <th>Вебмастеров</th>
            <th>Телеграм</th>
            <th>Телефон</th>
            <th>Управление</th>
          </tr>
        </thead>
        <tbody>
          {curators.map((curator) => {
            const {
              id,
              name,
              login,
              createdAt,
              workTelegram,
              workPhone,
              balance,
              webmasters,
              isBanned,
              // legalInformation,
            } = curator;

            return (
              <tr className="hover" key={id}>
                <td>{id}</td>
                <td className="font-medium">{login}</td>
                {/* <td>
                  <LegalInformationStatusBadge
                    status={legalInformation?.status}
                  />
                </td> */}
                <td className="font-medium">{name}</td>
                <td>{new Date(createdAt).toLocaleDateString()}</td>
                <td className="font-medium">{balance.balance} K</td>
                <td>{balance.totalEarned} K</td>
                <td>{webmasters.length}</td>
                <td>
                  {workTelegram ? (
                    <a
                      className="link link-primary"
                      target="_blank"
                      href={`https://t.me/${workTelegram}`}
                      rel="noreferrer"
                    >
                      @{workTelegram}
                    </a>
                  ) : (
                    "---"
                  )}
                </td>
                <td>{workPhone || "---"}</td>

                <td>
                  <div className="flex gap-2">
                    {/* <LegalInforamitionButton
                      status={legalInformation?.status}
                      role="curator"
                      userId={id}
                    /> */}

                    <div className="tooltip" data-tip="редактировать">
                      <button
                        className="btn btn-primary btn-xs capitalize"
                        onClick={() => {
                          dispatch(setEditCuratorAndOpenModal(curator));
                        }}
                      >
                        <PencilIcon />
                      </button>
                    </div>
                    <BanUserButton id={id} isBanned={isBanned} role="curator" />
                    <div className="tooltip" data-tip="удалить">
                      <button
                        className="btn btn-error btn-xs capitalize"
                        onClick={() =>
                          dispatch(
                            setRemoveUserAndOpenModal({
                              id,
                              login,
                              role: "curator",
                            })
                          )
                        }
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <EditCuratorModal />
      <RemoveUserModal />
    </div>
  );
};
