export const routing = {
  MAIN: "/",
  LOGIN: "login",
  CABINET: "cab",
  LEADS: "leads",
  NEWS: "news",
  ADD_NEWS: "add-news",
  EDIT_NEWS: "edit-news",
  OFFERS: "offers",
  ADD_OFFER: "add-offer",
  EDIT_OFFER: "edit-offer",
  WEBMASTERS: "webmasters",
  CURATORS: "curators",
  PAYMENTS: "payments",
  CATS_AND_CITIES: "categories-and-cities",
};

export const dateConfig: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
};

export const tokenLSKey = "a-ll-token";
