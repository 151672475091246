import { ITelephonyInputs, TelephonyType } from "@/types";
import "./TelephonyInputs.css";

type Props = {
  inputs: ITelephonyInputs[];
  add: () => void;
  remove: () => void;
  onChange: (
    id: number,
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
};

export const TelephonyInputs: React.FC<Props> = ({
  inputs,
  onChange,
  add,
  remove,
}) => {
  return (
    <div className="mt-3 telephony">
      {inputs.length > 0 && (
        <label className="label">
          <span className="label-text">Рекламные кампании</span>
        </label>
      )}
      {inputs.map((input, i) => (
        <div className={`form-control ${i === 0 ? "mb-5" : "my-5"}`} key={i}>
          <div className="input-group w-full">
            <select
              value={input.type}
              onChange={(e) => onChange(i, e)}
              name="type"
              className="select select-bordered select-sm"
            >
              <option value="none" disabled>
                Тип
              </option>
              <option value={TelephonyType.PRIVATE}>ЧМ</option>
              <option value={TelephonyType.COMPANY}>КП</option>
            </select>
            <input
              type="text"
              placeholder="Телефония"
              className="input input-bordered input-sm w-1/3"
              value={input.number}
              name="number"
              onChange={(e) => onChange(i, e)}
            />
            <input
              type="text"
              placeholder="Название"
              className="input input-bordered input-sm w-2/3"
              value={input.name}
              name="name"
              onChange={(e) => onChange(i, e)}
            />
          </div>
        </div>
      ))}

      <div className="flex">
        <label className="label">
          <span className="label-text link link-primary" onClick={add}>
            Добавить кампанию
          </span>
        </label>
        {inputs.length > 0 && (
          <label className="label">
            <span className="label-text link link-error" onClick={remove}>
              Убавить кампанию
            </span>
          </label>
        )}
      </div>
    </div>
  );
};
