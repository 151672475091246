import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { IAccessToken, ILoginData, IAdmin } from "@/types";
import { setAdmin } from "../features/adminSlice";
import { tokenLSKey } from "@/constants";

export const adminAPI = createApi({
  reducerPath: "adminApi",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(tokenLSKey);
      if (token) headers.set("authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  endpoints: (builder) => ({
    login: builder.mutation<IAccessToken, ILoginData>({
      query: (credentials) => ({
        url: "/auth/admin/login",
        method: "POST",
        body: credentials,
      }),
    }),

    getMe: builder.query<IAdmin, null>({
      query: () => ({
        url: "/admin/",
        method: "GET",
      }),

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAdmin(data));
        } catch (error) {}
      },
    }),
  }),
});

export const { useGetMeQuery, useLoginMutation } = adminAPI;
