import { IPayment } from '@/types';
import { FC, useState } from 'react';

type Props = {
  data: IPayment;
};

export const HistoryPaymentsMobCollapse: FC<Props> = ({ data }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div
      className={`mob-collapse bg-base-100 theme-border last:border-b-0 collapse collapse-arrow ${
        isOpen ? 'collapse-open' : ''
      }`}
    >
      <div
        className='collapse-title cursor-pointer min-h-[auto] py-2 px-2 min-[360px]:px-5'
        onClick={() => setIsOpen((p) => !p)}
      >
        <div className='flex gap-2 items-center flex-wrap justify-between'>
          <div className='mr-auto text-sm flex items-center gap-1'>
            <div className='font-bold'>ID:</div>
            <span>{data.id}</span>
          </div>
        </div>
      </div>

      <div className='collapse-content px-2 min-[360px]:px-5'>
        <div>
          <hr className={`divider h-[1px] ${isOpen && 'mt-0'}`} />
          <div className='flex flex-col items-start gap-1'>
            <div className='flex items-center gap-2 flex-wrap'>
              <label className='label p-0'>
                <span className='label-text font-medium'>ID заявки:</span>
              </label>
              <div>{data.id}</div>
            </div>

            <div className='flex items-center gap-2 flex-wrap'>
              <label className='label p-0'>
                <span className='label-text font-medium'>Дата создания:</span>
              </label>
              <div>
                {new Date(data.createdAt)
                  .toLocaleDateString(navigator.language, {
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                  .replace(',', '')}
              </div>
            </div>

            <div className='flex items-center gap-2 flex-wrap'>
              <label className='label p-0'>
                <span className='label-text font-medium'>Вебмастер:</span>
              </label>
              <div className='tooltip' data-tip={`ID: ${data.webmasterId}`}>
                <span className='font-medium'>{data.login || '---'}</span>
              </div>
            </div>

            <div className='flex items-center gap-2 flex-wrap'>
              <label className='label p-0'>
                <span className='label-text font-medium'>Куратор:</span>
              </label>
              <div className='tooltip' data-tip={`ID: ${data.curatorId}`}>
                <span className='font-medium'>{data.name || '---'}</span>
              </div>
            </div>

            <div className='flex items-center gap-2 flex-wrap'>
              <label className='label p-0'>
                <span className='label-text font-medium'>Сумма:</span>
              </label>
              <div>{data.sum} K</div>
            </div>

            <div className='flex items-center gap-2 flex-wrap'>
              <label className='label p-0'>
                <span className='label-text font-medium'>
                  Номер карты/Счет:
                </span>
              </label>
              <div>{data.bankCard}</div>
            </div>

            <div className='flex items-center gap-2 flex-wrap'>
              <label className='label p-0'>
                <span className='label-text font-medium'>Банк:</span>
              </label>
              <div>{data.bankCardName}</div>
            </div>

            <div className='flex items-center gap-2 flex-wrap'>
              <label className='label p-0'>
                <span className='label-text font-medium'>
                  Название организации/ФИО:
                </span>
              </label>
              <div>{data.fio ? data.fio : '---'}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
