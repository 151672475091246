import {
  ChangeEvent,
  FC,
  FormEventHandler,
  useEffect,
  Dispatch,
  MouseEventHandler,
} from 'react';
import { DeleteInputIcon } from '../Icons/DeleteInputIcon';

type Props = {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  value: string;
  onChange: (value: string) => void;
  setSearched?: Dispatch<React.SetStateAction<boolean>>;
  formHandler: FormEventHandler;
  deleteHandler: MouseEventHandler;
};

export const Search: FC<Props> = ({
  size = 'sm',
  value,
  onChange,
  setSearched,
  formHandler,
  deleteHandler,
}) => {
  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (setSearched) {
      setSearched(false);
    }

    onChange(e.target.value);
  };

  useEffect(() => {
    if (setSearched && value.length >= 3) {
      const timer = setTimeout(() => {
        setSearched(true);
      }, 2000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [value, setSearched]);

  return (
    <form className='form-control w-full sm:w-auto' onSubmit={formHandler}>
      <div className='input-group'>
        <input
          value={value}
          onChange={changeHandler}
          type='text'
          placeholder='Поиск...'
          className={`input input-${size} input-bordered w-full sm:w-auto`}
        />
        {value.length > 2 ? (
          <button
            type='button'
            className={`btn btn-square btn-primary btn-${size}`}
            onClick={deleteHandler}
          >
            <DeleteInputIcon />
          </button>
        ) : (
          <button
            type='submit'
            className={`btn btn-square btn-primary btn-${size} pointer-events-none`}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-4 w-4'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
              />
            </svg>
          </button>
        )}
      </div>
    </form>
  );
};
