import { ITelephonyInputs, LeadStatus, TelephonyType } from "@/types";

export const getStatusText = (status: LeadStatus) => {
  switch (status) {
    case LeadStatus.LEAD:
      return "В обработке";
    case LeadStatus.IN_WORK:
      return "В работе";
    case LeadStatus.APPROVED:
      return "Подтвержден";
    case LeadStatus.REJECTED:
      return "Отклонен";
  }
};

export const getLossReasonText = (comment: string | null) => {
  if (!comment) return null;

  switch (comment) {
    case "Пропала связь с клиентом":
      return "Пропала связь";
    case "Неплатёжеспособное лицо":
      return "Неплатёж. лицо";
    default:
      return comment;
  }
};

export const compareDates = (start: string, end: string) => {
  const startDateArr = start.split("/");
  const s = new Date(
    parseInt(startDateArr[0]),
    parseInt(startDateArr[1]) - 1,
    parseInt(startDateArr[2])
  );

  const endDateArr = end.split("/");
  const e = new Date(
    parseInt(endDateArr[0]),
    parseInt(endDateArr[1]) - 1,
    parseInt(endDateArr[2]),
    23,
    59,
    59
  );

  if (s.getTime() > e.getTime()) return false;
  return true;
};

export const validateLogin = (login: string) => {
  if (!login) return "Введите логин";

  if (login.length < 4 || login.length > 20)
    return "Логин должен быть от 4 до 20 символов.";

  const loginRegEx = /^[A-Za-z0-9_-]{3,20}$/;
  if (!loginRegEx.test(login)) return "Некорректный логин";
};

export const validateTelegram = (telegram: string) => {
  const tgReqex = /^@?[A-Za-z\d_]{5,32}$/;
  if (!tgReqex.test(telegram)) return "Некорректный телеграм";
};

export const validatePhone = (phone: string) => {
  const phoneReqex = /^\+?[\d ()-]{8,20}$/;
  if (!phoneReqex.test(phone)) return "Некорректный телефон";
};

export const validatePassword = (password: string) => {
  if (!password) return "Введите пароль";
  if (password.length < 6 || password.length > 30) {
    return "Пароль должен быть не меньше 6 и не больше 30 символов";
  }
};

export const validateTelephony = (data: ITelephonyInputs[]) => {
  let error: string | null = null;
  for (let i = 0; i < data.length; i++) {
    const rec = data[i];
    if (
      rec.type !== TelephonyType.COMPANY &&
      rec.type !== TelephonyType.PRIVATE
    ) {
      if (data.length > 1) {
        error = `Укажите тип в ${i + 1} кампании`;
      } else {
        error = "Укажите тип кампании";
      }
      break;
    }

    if (!rec.number) {
      if (data.length > 1) {
        error = `Укажите телефон в ${i + 1} кампании`;
      } else {
        error = "Укажите телефон кампании";
      }

      break;
    }

    const phoneReqex = /^\d{8,20}$/;
    if (!phoneReqex.test(rec.number)) {
      if (data.length > 1) {
        error = `Некорректный телефон в ${i + 1} кампании`;
      } else {
        error = "Некорректный телефон кампании";
      }
      break;
    }

    if (rec.name && rec.name.length > 40) {
      if (data.length > 1) {
        error = `Слишком длинное название в ${i + 1} кампании`;
      } else {
        error = "Слишком длинное название";
      }
      break;
    }
  }
  return error;
};
