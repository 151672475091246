import { LeadStatus } from "@/types";
import { getLossReasonText, getStatusText } from "@/utils";

type Props = {
  status: LeadStatus;
  comment: string | null;
  size?: string; // badge-{size}
};

export const LeadStatusBadge: React.FC<Props> = ({ status, comment, size }) => {
  const text = getStatusText(status);
  const lossReason = getLossReasonText(comment);

  if (status === LeadStatus.LEAD) {
    return (
      <span className={`badge badge-info ${size ? size : "w-32"}`}>{text}</span>
    );
  }

  if (status === LeadStatus.IN_WORK) {
    return (
      <span className={`badge badge-primary ${size ? size : "w-32"}`}>
        {text}
      </span>
    );
  }

  if (status === LeadStatus.APPROVED) {
    return (
      <span className={`badge badge-success ${size ? size : "w-32"}`}>
        {text}
      </span>
    );
  }

  if (status === LeadStatus.REJECTED) {
    return (
      <>
        <span className={`badge badge-error ${size ? size : "w-32"}`}>
          {lossReason ? lossReason : text}
        </span>
      </>
    );
  }

  return <span></span>;
};
