import { IPayment } from '@/types';

type Props = {
  payments: IPayment[];
};

export const HistoryPaymentsTable: React.FC<Props> = ({ payments }) => {
  return (
    <div className='overflow-x-auto'>
      <table className='table w-full table-compact'>
        <thead>
          <tr>
            <th>ID заявки</th>
            <th>Дата создания</th>
            <th>Вебмастер</th>
            <th>Куратор</th>
            <th>Сумма</th>
            <th>Номер карты/Счет</th>
            <th>Банк</th>
            <th>Название организации/ФИО</th>
          </tr>
        </thead>
        <tbody>
          {payments.map((payment) => {
            const {
              id,
              createdAt,
              curatorId,
              name,
              sum,
              bankCard,
              login,
              bankCardName,
              fio,
              webmasterId,
            } = payment;

            return (
              <tr className='hover' key={id}>
                <td>{id}</td>
                <td>
                  {new Date(createdAt)
                    .toLocaleDateString(navigator.language, {
                      hour: '2-digit',
                      minute: '2-digit',
                    })
                    .replace(',', '')}
                </td>
                <td>
                  {webmasterId ? (
                    <div
                      className='tooltip'
                      data-tip={`ID: ${webmasterId} Логин: ${login}`}
                    >
                      <span className='font-medium'>{login || '---'}</span>
                    </div>
                  ) : (
                    '---'
                  )}
                </td>
                <td>
                  {curatorId ? (
                    <div
                      className='tooltip'
                      data-tip={`ID: ${curatorId} Логин: ${login}`}
                    >
                      <span className='font-medium'>{name}</span>
                    </div>
                  ) : (
                    '---'
                  )}
                </td>
                <td className='font-medium'>{sum} K</td>
                <td>{bankCard}</td>
                <td>{bankCardName}</td>
                <td>{fio ? fio : '---'}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
