import { useAppDispatch } from "@/hooks/useRedux";
import { toggleAddCityModal } from "@/redux/features/appSlice";

export const AddCity = () => {
  const dispatch = useAppDispatch();
  return (
    <div className="bg-base-100 shadow theme-border rounded-md px-6 py-4 w-full xl:w-auto">
      <div className="opacity-60">Добавить город</div>
      <button
        onClick={() => dispatch(toggleAddCityModal())}
        className="btn btn-success btn-sm mt-2 w-full"
      >
        Добавить
      </button>
    </div>
  );
};
