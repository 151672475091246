import { useCallback, useState } from "react";
import { RemoveCityModal } from "@/components/Modals/RemoveCityModal/RemoveCityModal";
import { ICitiesTableData, IRemoveCityModalData } from "@/types";

type Props = {
  cities: ICitiesTableData[];
};

export const CitiesTable: React.FC<Props> = ({ cities }) => {
  const [removeCityModalData, setRemoveCityModalData] =
    useState<IRemoveCityModalData>({
      isOpen: false,
      id: null,
      name: null,
    });

  const openRemoveModalHandler = (id: number, name: string) => {
    setRemoveCityModalData({
      isOpen: true,
      id,
      name,
    });
  };

  const closeRemoveModalHandler = useCallback(() => {
    setRemoveCityModalData({
      isOpen: false,
      id: null,
      name: null,
    });
  }, []);

  return (
    <>
      <div className="overflow-x-auto">
        <table className="table  w-full">
          <thead>
            <tr>
              <th>ID</th>
              <th>Название</th>
              <th>Офферов</th>
              <th>Управление</th>
            </tr>
          </thead>
          <tbody>
            {cities.map((city) => {
              return (
                <tr className="hover" key={city.id}>
                  <td>{city.id}</td>
                  <td>{city.name}</td>

                  <td className="font-medium">{city.offers}</td>
                  <td>
                    <div className="flex gap-2">
                      <button
                        className={`btn btn-error  btn-xs capitalize ${
                          city.offers ? "btn-disabled" : ""
                        }`}
                        onClick={() =>
                          openRemoveModalHandler(city.id, city.name)
                        }
                      >
                        удалить
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <RemoveCityModal
        data={removeCityModalData}
        callBack={closeRemoveModalHandler}
      />
    </>
  );
};
