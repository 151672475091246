import { tokenLSKey } from "@/constants";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import {
  IAddWebmaster,
  IEditWebmaster,
  IWebmastersResponse,
  IRemoveDataResponse,
  IWebmaster,
  IGetWebmastersQuery,
} from "@/types";

export const webmastersAPI = createApi({
  reducerPath: "webmastersAPI",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/webmaster",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(tokenLSKey);

      if (token) headers.set("authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  tagTypes: ["Webmasters"],
  endpoints: (builder) => ({
    getAllWebmasters: builder.query<IWebmastersResponse, IGetWebmastersQuery>({
      query: (params) => ({
        url: "all/",
        method: "GET",
        params,
      }),
      providesTags: (result) => ["Webmasters"],
    }),
    addWebmaster: builder.mutation<IWebmaster, IAddWebmaster>({
      query: (body) => ({
        url: "",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Webmasters"],
    }),

    removeWebmaster: builder.mutation<IRemoveDataResponse, { id: number }>({
      query: (body) => ({
        url: "",
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Webmasters"],
    }),
    editWebmaster: builder.mutation<IWebmaster, IEditWebmaster>({
      query: (body) => ({
        url: "",
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Webmasters"],
    }),
    banWebmaster: builder.mutation<
      { message: string },
      { id: number; isBanned: boolean }
    >({
      query: (body) => ({
        url: "/ban",
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Webmasters"],
    }),
    getWebmastersSearch: builder.query<
      { count: number; rows: IWebmaster[] },
      { query: string }
    >({
      query: (params) => ({
        url: "search",
        method: "GET",
        params,
      }),
    }),
  }),
});

export const {
  useGetAllWebmastersQuery,
  useAddWebmasterMutation,
  useRemoveWebmasterMutation,
  useEditWebmasterMutation,
  useBanWebmasterMutation,
  useGetWebmastersSearchQuery,
} = webmastersAPI;
