import { IWebmaster } from "@/types";
import { useAppDispatch } from "@/hooks/useRedux";
import { setEditWebmasterAndOpen } from "@/redux/features/editWebmasterModalSlice";
import { setRemoveUserAndOpenModal } from "@/redux/features/removeUserModalSlice";
import {
  TelephonyCeil,
  BanUserButton,
  PencilIcon,
  TrashIcon,
  // LegalInformationStatusBadge,
  // LegalInforamitionButton,
} from "@/components";

type props = {
  webmasters: IWebmaster[];
};

export const WebmastersTable: React.FC<props> = ({ webmasters }) => {
  const dispatch = useAppDispatch();

  return (
    <div className="overflow-x-auto ">
      <table className="table table-compact w-full">
        <thead>
          <tr>
            <th>ID</th>
            <th>Логин</th>
            <th>Добавлен</th>
            <th>Рекл. компании</th>
            <th>Баланс</th>
            <th>Холд</th>
            <th>Заработано</th>
            <th>Телеграм</th>
            <th>Телефон</th>
            {/* <th>Куратор</th> */}
            <th>Реферрер</th>
            <th>Управление</th>
          </tr>
        </thead>
        <tbody>
          {webmasters.map((webmaster) => {
            const {
              id,
              login,
              createdAt,
              telephony,
              workTelegram,
              workPhone,
              balance,
              refName,
              refId,
              isBanned,
              // curator,
              //legalInformation,
            } = webmaster;

            return (
              <tr className="hover" key={id}>
                <td>{id}</td>
                <td className="font-medium">{login}</td>

                <td>{new Date(createdAt).toLocaleDateString()}</td>
                <td>
                  {telephony.length
                    ? telephony.map((t) => (
                        <TelephonyCeil
                          key={t.number}
                          name={t.name}
                          number={t.number}
                          type={t.type}
                        />
                      ))
                    : "---"}
                </td>
                <td className="font-medium">{balance.balance} K</td>
                <td>{balance.hold} K</td>
                <td>{balance.totalEarned} K</td>
                <td>
                  {workTelegram ? (
                    <a
                      className="link link-primary"
                      target="_blank"
                      href={`https://t.me/${workTelegram}`}
                      rel="noreferrer"
                    >
                      @{workTelegram}
                    </a>
                  ) : (
                    "---"
                  )}
                </td>
                <td>{workPhone || "---"}</td>

                {/* <td>
                  {curator ? (
                    <span className="badge badge-success">{curator.name}</span>
                  ) : (
                    "---"
                  )}
                </td> */}
                <td>
                  {refName && refId ? (
                    <span className="tooltip" data-tip={`ID: ${refId}`}>
                      {refName}
                    </span>
                  ) : (
                    "---"
                  )}
                </td>
                <td>
                  <span className="flex gap-2">
                    <div className="tooltip" data-tip="редактировать">
                      <button
                        className="btn btn-primary btn-xs capitalize"
                        onClick={() =>
                          dispatch(setEditWebmasterAndOpen(webmaster))
                        }
                      >
                        <PencilIcon />
                      </button>
                    </div>
                    <BanUserButton
                      id={id}
                      isBanned={isBanned}
                      role="webmaster"
                    />
                    <div className="tooltip" data-tip="удалить">
                      <button
                        className="btn btn-error btn-xs capitalize"
                        onClick={() =>
                          dispatch(
                            setRemoveUserAndOpenModal({
                              id,
                              login,
                              role: "webmaster",
                            })
                          )
                        }
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
