import { NavLink } from "react-router-dom";
import {
  NewsIcon,
  DiamondIcon,
  ReportMoneyIcon,
  Logo,
  UsersIcon,
  WalletIcon,
  BriefcaseIcon,
  // UserPlusIcon,
} from "@/components";
import "./MainDrawer.css";
import { useAppSelector } from "@/hooks/useRedux";
import { app } from "@/redux/features/appSlice";
import { useGetStatsQuery } from "@/redux/api/statsAPI";
import { routing } from "@/constants";

type DrawerLinkProps = {
  text: string;
  to: string;
  type?: "link";
  icon: JSX.Element;
};

export const MainDrawer = () => {
  const { mainDrawerIsOpen } = useAppSelector(app);

  const { data } = useGetStatsQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const paymentsCount =
    data && data.waitingPayments.curators + data.waitingPayments.webmasters > 0
      ? data.waitingPayments.curators + data.waitingPayments.webmasters
      : 0;

  const badgeClass = data
    ? data.waitingPayments.webmasters > 0
      ? "badge-error"
      : data.waitingPayments.curators > 0
      ? "badge-accent"
      : ""
    : "";

  const DrawerLink: React.FC<DrawerLinkProps> = ({ text, to, icon, type }) => {
    if (type === "link") {
      return mainDrawerIsOpen ? (
        <a href={to} rel="noreferrer" target="_blank">
          {icon}
          <span>{text}</span>
        </a>
      ) : (
        <div className="tooltip tooltip-right" data-tip={text}>
          <a href={to} rel="noreferrer" target="_blank">
            {icon}
          </a>
        </div>
      );
    }

    return mainDrawerIsOpen ? (
      <NavLink to={to}>
        {icon}
        <span>{text}</span>
      </NavLink>
    ) : (
      <div className="tooltip tooltip-right" data-tip={text}>
        <NavLink to={to}>{icon}</NavLink>
      </div>
    );
  };

  return (
    <div
      className={`hidden lg:block main-drawer bg-base-100  theme-border ${
        mainDrawerIsOpen ? "lg:w-[270px]" : "lg:w-[70px]"
      }`}
    >
      <div className="logo-wrapper theme-border cursor-pointer">
        <div
          className="flex justify-center h-full items-center gap-3"
          onClick={() =>
            (window.location.href = `/${routing.CABINET}/${routing.WEBMASTERS}`)
          }
        >
          <div className="logo ">
            <Logo />
          </div>
          {mainDrawerIsOpen && (
            <h1 className="font-bold text-lg brand-color brand-name">
              LEY-LID.RU
            </h1>
          )}
        </div>
      </div>
      <ul
        className={`drawer-menu mt-5 gap-2 ${
          mainDrawerIsOpen ? "px-5" : "px-1"
        }`}
      >
        <li>
          <DrawerLink
            to={routing.WEBMASTERS}
            text="Вебмастера"
            icon={<UsersIcon />}
          />
        </li>

        {/* <li>
          <DrawerLink
            to={routing.CURATORS}
            text="Кураторы"
            icon={<UserPlusIcon />}
          />
        </li> */}

        <li>
          <DrawerLink
            to={routing.LEADS}
            text="Лиды"
            icon={<ReportMoneyIcon />}
          />
        </li>

        <li>
          {mainDrawerIsOpen ? (
            <NavLink to={routing.PAYMENTS} className={"items-center"}>
              <WalletIcon />
              <span>Выплаты</span>
              {paymentsCount ? (
                <div className={`badge ${badgeClass}`}>{paymentsCount}</div>
              ) : (
                ""
              )}
            </NavLink>
          ) : (
            <div className="tooltip tooltip-right" data-tip="Выплаты">
              <NavLink to={routing.PAYMENTS}>
                {paymentsCount ? (
                  <div className={`badge ${badgeClass} my-1`}>
                    {paymentsCount}
                  </div>
                ) : (
                  <WalletIcon />
                )}
              </NavLink>
            </div>
          )}
        </li>

        <li>
          <DrawerLink to={routing.NEWS} text="Новости" icon={<NewsIcon />} />
        </li>

        <li>
          <DrawerLink
            to={routing.OFFERS}
            text="Офферы"
            icon={<DiamondIcon />}
          />
        </li>

        <li>
          <DrawerLink
            to={routing.CATS_AND_CITIES}
            text="Категории и города"
            icon={<BriefcaseIcon />}
          />
        </li>
      </ul>
    </div>
  );
};
