export const Logo = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="60.000000pt"
      height="60.000000pt"
      viewBox="0 0 60.000000 60.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,60.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M120 300 l0 -270 190 0 190 0 0 45 0 45 -135 0 -135 0 0 225 0 225
-55 0 -55 0 0 -270z"
        />
        <path
          d="M300 305 l0 -145 105 0 105 0 0 25 c0 24 -2 25 -75 25 l-75 0 0 120
0 120 -30 0 -30 0 0 -145z"
        />
      </g>
    </svg>
  );
};
