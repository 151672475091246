import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { admin } from "@/redux/features/adminSlice";
import { UserIcon, Logout, ThemeSwitch, BurgerIcon, Logo } from "@/components";
import { toggleMainDrawer } from "@/redux/features/appSlice";

import "./TopNavBar.css";
import { routing } from "@/constants";

export const TopNavBar = () => {
  const { login } = useAppSelector(admin);
  const dispatch = useAppDispatch();

  return (
    <div className="top-navbar  flex justify-between items-center  px-3 md:px-10">
      <div
        className="cursor-pointer no-select hidden lg:block"
        onClick={() => dispatch(toggleMainDrawer())}
      >
        <BurgerIcon />
      </div>

      <div className="logo-wrapper lg:hidden cursor-pointer">
        <div
          className="flex justify-center h-full items-center gap-3"
          onClick={() =>
            (window.location.href = `/${routing.CABINET}/${routing.WEBMASTERS}`)
          }
        >
          <div className="logo  hidden sm:block">
            <Logo />
          </div>
          <h1 className="font-bold  text-lg brand-color brand-name">
            LEY-LID.RU
          </h1>
        </div>
      </div>

      <div className="profile-box flex items-center gap-1">
        <div className="profile mr-2 flex items-center gap-3 text-lg">
          <span className="hidden sm:block">{login} </span>
          <div
            className="bg-info rounded-full "
            style={{ padding: "0.55rem", scale: ".8" }}
          >
            <UserIcon />
          </div>
        </div>
        <div className="icons-menu flex gap-3 items-center">
          <ThemeSwitch />
          <Logout />
        </div>
      </div>
    </div>
  );
};
