import { IOffer } from "@/types";
import { OfferItem } from "../OfferItem/OfferItem";

type Props = {
  offers: IOffer[];
  remove: (id: number, name: string) => void;
  changeStatus: (offerId: number, isPaused: boolean) => void;
};

export const OffersList: React.FC<Props> = ({
  offers,
  remove,
  changeStatus,
}) => {
  return (
    <>
      {offers.map((offer) => {
        return (
          <OfferItem
            key={offer.id}
            offer={offer}
            remove={remove}
            changeStatus={changeStatus}
          />
        );
      })}
    </>
  );
};
