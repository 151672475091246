import { Link } from "react-router-dom";

import { dateConfig } from "@/constants";
import { INewsPrewiew } from "@/types";

import "./NewsItem.css";
import { EyeIcon } from "@/components/Icons/EyeIcon";
import { PencilIcon } from "@/components/Icons/PencilIcon";
import { TrashIcon } from "@/components/Icons/TrashIcon";

export const NewsItem: React.FC<{
  item: INewsPrewiew;
  remove: (id: number, title: string) => void;
}> = ({ item, remove }) => {
  const publishDate = new Date(item.createdAt).toLocaleTimeString(
    navigator.language,
    dateConfig
  );

  const updatedDate = new Date(item.updatedAt).toLocaleTimeString(
    navigator.language,
    dateConfig
  );

  const removeHandler = () => {
    remove(item.id, item.title);
  };

  return (
    <div className="news-item flex lg:justify-between  lg:flex-row flex-col p-5 bg-base-100  rounded-md my-5 shadow">
      <div>
        <div className="title text-2xl font-medium ">{item.title}</div>
        <div className="stat-title">
          <div>ID: {item.id}</div>
          <div className="hidden sm:block">
            дата публикации: {publishDate.replace(",", "")}
          </div>
          <div className="hidden sm:block">
            последее изменение: {updatedDate.replace(",", "")}
          </div>
        </div>
      </div>
      <div className="actions-buttons flex  gap-3 sm:items-end mt-5 lg:mt-0">
        <div className="tooltip" data-tip="Посмотреть">
          <Link
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-success btn-sm lowercase"
            to={"/cab/news/" + item.id}
          >
            <EyeIcon />
          </Link>
        </div>
        <div className="tooltip" data-tip="Редактировать">
          <Link
            to={"/cab/edit-news/" + item.id}
            className="btn btn-primary btn-sm lowercase"
          >
            <PencilIcon />
          </Link>
        </div>
        <div className="tooltip" data-tip="Удалить">
          <button
            className="btn btn-error btn-sm lowercase"
            onClick={removeHandler}
          >
            <TrashIcon />
          </button>
        </div>
      </div>
    </div>
  );
};
