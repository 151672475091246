import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { routing } from "./constants";

import { Login } from "./pages/Login/Login";
import { Webmasters } from "./pages/Webmasters/Webmasters";
import { AuthLayout, Layout } from "./components";

const Curators = lazy(() => import("./pages/Curators/Curators"));
const News = lazy(() => import("./pages/News/News"));
const AddNews = lazy(() => import("./pages/AddNews/AddNews"));
const SingleNews = lazy(() => import("./pages/SingleNews/SingleNews"));
const EditNews = lazy(() => import("./pages/EditNews/EditNews"));
const Offers = lazy(() => import("./pages/Offers/Offers"));
const AddOffer = lazy(() => import("./pages/AddOffer/AddOffer"));
const SingleOffer = lazy(() => import("./pages/SingleOffer/SingleOffer"));
const EditOffer = lazy(() => import("./pages/EditOffer/EditOffer"));
const Payments = lazy(() => import("./pages/Payments/Payments"));
const Leads = lazy(() => import("./pages/Leads/Leads"));
const CategiesAndCities = lazy(
  () => import("./pages/CategiesAndCities/CategiesAndCities")
);

export const Routing = () => {
  return (
    <Routes>
      <Route path={routing.LOGIN} element={<Login />} />
      <Route path={routing.MAIN} element={<Navigate to={routing.CABINET} />} />

      <Route path={routing.CABINET} element={<AuthLayout />}>
        <Route path="" element={<Layout />}>
          <Route index element={<Navigate to={routing.WEBMASTERS} />} />
          <Route path={routing.WEBMASTERS} element={<Webmasters />} />
          <Route
            path={routing.CURATORS}
            element={
              <Suspense>
                <Curators />
              </Suspense>
            }
          />
          <Route
            path={routing.NEWS}
            element={
              <Suspense>
                <News />
              </Suspense>
            }
          />
          <Route
            path={routing.ADD_NEWS}
            element={
              <Suspense>
                <AddNews />
              </Suspense>
            }
          />
          <Route
            path={`${routing.NEWS}/:id`}
            element={
              <Suspense>
                <SingleNews />
              </Suspense>
            }
          />
          <Route
            path={`${routing.EDIT_NEWS}/:id`}
            element={
              <Suspense>
                <EditNews />
              </Suspense>
            }
          />
          <Route
            path={routing.OFFERS}
            element={
              <Suspense>
                <Offers />
              </Suspense>
            }
          />
          <Route
            path={routing.ADD_OFFER}
            element={
              <Suspense>
                <AddOffer />
              </Suspense>
            }
          />
          <Route
            path={`${routing.OFFERS}/:id`}
            element={
              <Suspense>
                <SingleOffer />
              </Suspense>
            }
          />
          <Route
            path={`${routing.EDIT_OFFER}/:id`}
            element={
              <Suspense>
                <EditOffer />
              </Suspense>
            }
          />

          <Route
            path={routing.PAYMENTS}
            element={
              <Suspense>
                <Payments />
              </Suspense>
            }
          />
          <Route
            path={routing.LEADS}
            element={
              <Suspense>
                <Leads />
              </Suspense>
            }
          />
          <Route
            path={routing.CATS_AND_CITIES}
            element={
              <Suspense>
                <CategiesAndCities />
              </Suspense>
            }
          />
          <Route path="*" element={<Navigate to={routing.WEBMASTERS} />} />
        </Route>
      </Route>

      <Route path="*" element={<AuthLayout />} />
    </Routes>
  );
};
