import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { tokenLSKey } from "@/constants";
import { IConfirmWebmaster, IRegisterRequestsResponse } from "@/types";

export const registerRequestAPI = createApi({
  reducerPath: "registerRequestAPI",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/register-request",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(tokenLSKey);

      if (token) headers.set("authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  tagTypes: ["RegisterRequests"],
  endpoints: (builder) => ({
    getRegisterRequests: builder.query<IRegisterRequestsResponse, null>({
      query: () => ({
        url: "",
        method: "GET",
      }),
      providesTags: (result) => ["RegisterRequests"],
    }),
    confirmRegister: builder.mutation<{ message: string }, IConfirmWebmaster>({
      query: (body) => ({
        url: "",
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["RegisterRequests"],
    }),
    removeRegisterRequest: builder.mutation<
      { message: string },
      { id: number }
    >({
      query: (body) => ({
        url: "",
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["RegisterRequests"],
    }),
  }),
});

export const {
  useGetRegisterRequestsQuery,
  useConfirmRegisterMutation,
  useRemoveRegisterRequestMutation,
} = registerRequestAPI;
