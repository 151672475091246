import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { tokenLSKey } from "@/constants";
import {
  IAddOffer,
  IEditOffer,
  IOffersResponse,
  IOfferWithContent,
  IRemoveDataResponse,
} from "@/types";

export const offersAPI = createApi({
  reducerPath: "offersAPI",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/offers/",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(tokenLSKey);

      if (token) headers.set("authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  tagTypes: ["Offers"],
  endpoints: (builder) => ({
    getAllOffers: builder.query<
      IOffersResponse,
      {
        offset: number;
        limit: number;
        cityId: number | undefined;
        categoryId: number | undefined;
        status: string | undefined;
      } | null
    >({
      query: (params) => ({
        url: "",
        method: "GET",
        params: {
          offset: params?.offset,
          limit: params?.limit,
          cityId: params?.cityId,
          categoryId: params?.categoryId,
          status: params?.status,
        },
      }),
      providesTags: (result) => ["Offers"],
    }),

    getOfferById: builder.query<IOfferWithContent, { id: number }>({
      query: ({ id }) => ({
        url: `${id}`,
        method: "GET",
      }),
      providesTags: (result) => ["Offers"],
    }),

    addOffer: builder.mutation<IOfferWithContent, IAddOffer>({
      query: (body) => ({
        url: "",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Offers"],
    }),

    changeOfferStatus: builder.mutation<
      IOfferWithContent,
      { offerId: number; isPaused: boolean }
    >({
      query: (body) => ({
        url: "status",
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Offers"],
    }),

    editOffer: builder.mutation<IOfferWithContent, IEditOffer>({
      query: (body) => ({
        url: `${body.id}`,
        method: "PATCH",
        body,
      }),
    }),

    removeOffer: builder.mutation<IRemoveDataResponse, { id: number }>({
      query: (body) => ({
        url: "",
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Offers"],
    }),
  }),
});

export const {
  useGetAllOffersQuery,
  useRemoveOfferMutation,
  useAddOfferMutation,
  useGetOfferByIdQuery,
  useEditOfferMutation,
  useChangeOfferStatusMutation,
} = offersAPI;
