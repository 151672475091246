import { useEffect } from "react";
import { NotificationManager as notify } from "react-notifications";

import { IErrorResponse, IRemoveCityModalData } from "@/types";
import { useRemoveCityMutation } from "@/redux/api/citiesAPI";

type Props = {
  data: IRemoveCityModalData;
  callBack: () => void;
};

export const RemoveCityModal: React.FC<Props> = ({ data, callBack }) => {
  const [removeCity, { isSuccess, error }] = useRemoveCityMutation();

  const cancelRemoveHandler = () => callBack();

  const removeHandler = (id: number) => {
    removeCity({ id });
  };

  useEffect(() => {
    if (!isSuccess) return;
    notify.success("Город удалён");
    callBack();
  }, [isSuccess, callBack]);

  useEffect(() => {
    if (!error) return;
    const { data } = error as IErrorResponse;
    notify.error(data.message);
  }, [error]);

  return (
    <div className={`modal ${data.isOpen ? "modal-open" : ""}`}>
      <div className="modal-box">
        <h3 className="font-bold text-lg">Удаление города</h3>
        <p className="py-4">
          Вы действительно хотите удалить город <b>{data.name}</b> ?
        </p>
        <div className="modal-action">
          <button
            className="btn btn-sm btn-primary"
            onClick={cancelRemoveHandler}
          >
            отмена
          </button>
          <button
            className="btn btn-sm btn-error"
            onClick={() => removeHandler(data.id!)}
          >
            Да
          </button>
        </div>
      </div>
    </div>
  );
};
