import { ILeadsState, RootState } from "@/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: ILeadsState = {
  startDate: null,
  endDate: null,
};

export const leadsSlice = createSlice({
  initialState,
  name: "leads",
  reducers: {
    setLeadsStartDate: (state, action: PayloadAction<string | null>) => {
      if (action.payload) {
        state.startDate = action.payload;
      } else {
        state.startDate = null;
      }
    },
    setLeadsEndDate: (state, action: PayloadAction<string | null>) => {
      if (action.payload) {
        state.endDate = action.payload;
      } else {
        state.endDate = null;
      }
    },
  },
});

export default leadsSlice.reducer;

export const { setLeadsStartDate, setLeadsEndDate } = leadsSlice.actions;
export const leads = (state: RootState) => state.leads;
