import { ILegalInformationModalState, RootState } from '@/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: ILegalInformationModalState = {
  userId: null,
  role: null,
  isOpen: false,
};

export const legalInformationModalSlice = createSlice({
  initialState,
  name: 'LegalInformationModal',
  reducers: {
    setUserIdAndRoleAndOpen: (
      state,
      action: PayloadAction<{ userId: number; role: 'webmaster' | 'curator' }>
    ) => {
      state.isOpen = true;
      state.role = action.payload.role;
      state.userId = action.payload.userId;
    },
    clearLegalInformationAndClose: (state) => {
      state.isOpen = false;
      state.userId = null;
      state.role = null;
    },
  },
});

export default legalInformationModalSlice.reducer;

export const { setUserIdAndRoleAndOpen, clearLegalInformationAndClose } =
  legalInformationModalSlice.actions;
export const legalInformationModal = (state: RootState) =>
  state.legalInformationModal;
