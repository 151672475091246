import { useAppDispatch } from "@/hooks/useRedux";
import {
  toggleAddWebmasterModal,
  toggleAddCuratorModal,
} from "@/redux/features/appSlice";

type Props = {
  role: "webmaster" | "curator";
};

export const AddUserItem: React.FC<Props> = ({ role }) => {
  const dispatch = useAppDispatch();

  const addBtnHandler = () => {
    if (role === "webmaster") dispatch(toggleAddWebmasterModal());
    if (role === "curator") dispatch(toggleAddCuratorModal());
  };

  return (
    <div className="bg-base-100 shadow theme-border rounded-md px-6 py-4">
      <label className="label">
        <span className="label-text">
          Добавить {role === "curator" ? "куратора" : "вебмастера"}
        </span>
      </label>
      <button
        onClick={addBtnHandler}
        className="btn btn-success btn-sm w-full md:w-32"
      >
        Добавить
      </button>
    </div>
  );
};
