import { useEffect } from "react";
import { NotificationManager as notify } from "react-notifications";

import { useRemoveWebmasterMutation } from "@/redux/api/webmastersAPI";
import { IErrorResponse } from "@/types";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { removeUserModal } from "@/redux/features/removeUserModalSlice";
import { clearRemoveUserAndCloseModal } from "@/redux/features/removeUserModalSlice";
import { useRemoveCuratorMutation } from "@/redux/api/curatorsAPI";

export const RemoveUserModal = () => {
  const dispatch = useAppDispatch();
  const { login, id, role, isOpen } = useAppSelector(removeUserModal);
  const [
    removeWebmaster,
    { isSuccess: webmasterIsSuccess, error: webmasterError },
  ] = useRemoveWebmasterMutation();

  const [removeCurator, { isSuccess: curatorIsSuccess, error: curatorError }] =
    useRemoveCuratorMutation();

  const userTypeText = role === "webmaster" ? "вебмастера" : "куратора";

  const removeBtnHandler = () => {
    if (!id) return;
    if (role === "webmaster") removeWebmaster({ id });
    if (role === "curator") removeCurator({ id });
  };

  useEffect(() => {
    if (!webmasterIsSuccess) return;
    notify.success("Вебмастер удалён");
    dispatch(clearRemoveUserAndCloseModal());
  }, [webmasterIsSuccess, dispatch]);

  useEffect(() => {
    if (!webmasterError) return;
    const { data } = webmasterError as IErrorResponse;
    if (data) return notify.error(data.message);
    notify.error("Что-то пошло не так");
  }, [webmasterError]);

  useEffect(() => {
    if (!curatorIsSuccess) return;
    notify.success("Куратор удалён");
    dispatch(clearRemoveUserAndCloseModal());
  }, [curatorIsSuccess, dispatch]);

  useEffect(() => {
    if (!curatorError) return;
    const { data } = curatorError as IErrorResponse;
    if (data) return notify.error(data.message);
    notify.error("Что-то пошло не так");
  }, [curatorError]);

  return (
    <div className={`modal ${isOpen ? "modal-open" : ""}`}>
      <div className="modal-box">
        <h3 className="font-bold text-lg">Удаление {userTypeText}</h3>
        <p className="py-4">
          Вы действительно хотите удалить {userTypeText} <b>{login}</b>?
        </p>

        <div className="modal-action">
          <button
            className="btn btn-sm btn-primary"
            onClick={() => dispatch(clearRemoveUserAndCloseModal())}
          >
            отмена
          </button>
          <button className="btn btn-sm btn-error" onClick={removeBtnHandler}>
            Да
          </button>
        </div>
      </div>
    </div>
  );
};
