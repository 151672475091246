import {
  IPayment,
  IPaymentModalState,
  RootState,
} from "@/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: IPaymentModalState = {
  isOpen: false,
  data: null,
};

export const paymentModalSlice = createSlice({
  initialState,
  name: "paymentModal",
  reducers: {
    setPaymentDataAndOpen: (
      state,
      action: PayloadAction<IPayment>
    ) => {
      state.isOpen = true;
      state.data = action.payload;
    },
    clearPaymentDataAndClose: (state) => {
      state.isOpen = false;
      state.data = null;
    },
  },
});

export default paymentModalSlice.reducer;

export const { setPaymentDataAndOpen, clearPaymentDataAndClose } =
  paymentModalSlice.actions;
export const paymentModal = (state: RootState) => state.paymentModal;
