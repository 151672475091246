import { Link } from 'react-router-dom';

import {
  EyeIcon,
  PauseIcon,
  PencilIcon,
  PlayIcon,
  TrashIcon,
  UserIcon,
  UsersIcon,
} from '@/components';

import { dateConfig } from '@/constants';

import { IOffer } from '@/types';

import './OfferItem.css';

type Props = {
  offer: IOffer;
  remove: (id: number, name: string) => void;
  changeStatus: (offerId: number, isPaused: boolean) => void;
};

export const OfferItem: React.FC<Props> = ({ offer, remove, changeStatus }) => {
  const publishDate = new Date(offer.createdAt).toLocaleTimeString(
    navigator.language,
    dateConfig
  );

  const updatedDate = new Date(offer.updatedAt).toLocaleTimeString(
    navigator.language,
    dateConfig
  );

  const removeHandler = () => {
    remove(offer.id, offer.name);
  };

  return (
    <div className='offer-item flex lg:justify-between xl:flex-row flex-col p-5 bg-base-100 rounded-md my-5 shadow'>
      <div className='flex flex-col md:flex-row items-center'>
        <div
          className='rounded-md h-40 w-40 border  md:mr-5 theme-border offer-img'
          style={{
            backgroundImage: `url(${process.env.REACT_APP_API_URL}${offer.category.img})`,
          }}
        ></div>
        <div>
          <div className='title text-2xl font-medium capitalize text-center md:text-left'>
            {offer.name}
          </div>
          <div className='stat-title mt-1 hidden md:block'>
            <div>ID: {offer.id}</div>
            <div>Дата добавления : {publishDate.replace(',', '')}</div>
            <div>Дата изменения : {updatedDate.replace(',', '')}</div>
          </div>
          <div className='flex gap-3 mt-3 justify-center items-center md:justify-start flex-col md:flex-row'>
            <span className='badge md:badge-lg badge-primary px-5'>
              {offer.city.name.toUpperCase()}
            </span>
            {offer.isPaused && (
              <span className='uppercase badge md:badge-lg badge-warning px-5'>
                Остановлен
              </span>
            )}
          </div>
        </div>
      </div>
      <div className='flex flex-col justify-between mt-3 '>
        <div className='lg:pr-5 flex flex-col items-center md:block'>
          <p className='text-lg pl-1 mb-2'>Цена за заявку:</p>
          <div className='flex justify-between gap-5  w-60  '>
            <div className='flex items-center gap-2'>
              <span
                className='rounded-full bg-primary p-1'
                style={{ scale: '0.8' }}
              >
                <UserIcon />
              </span>
              <span>Частное лицо </span>
            </div>
            <span className='font-medium text-xl pt-0.5 '>
              {offer.leadPrice} K
            </span>
          </div>
          {offer.companyLeadPrice && (
            <div className='flex justify-between gap-5 w-60'>
              <div className='flex items-center gap-2'>
                <span
                  className='rounded-full bg-primary p-1'
                  style={{ scale: '0.8' }}
                >
                  <UsersIcon />
                </span>
                <span>Компания </span>
              </div>
              <span className='font-medium text-xl pt-0.5'>
                {offer.companyLeadPrice} K
              </span>
            </div>
          )}
        </div>
        <div className='actions-buttons flex  gap-3 mt-3 justify-center md:justify-start'>
          <div className='tooltip' data-tip='Посмотреть'>
            <Link
              target='_blank'
              rel='noopener noreferrer'
              className='btn btn-success btn-sm lowercase'
              to={'/cab/offers/' + offer.id}
            >
              <EyeIcon />
            </Link>
          </div>
          <div className='tooltip' data-tip='Редактировать'>
            <Link
              to={'/cab/edit-offer/' + offer.id}
              className='btn btn-primary btn-sm lowercase'
            >
              <PencilIcon />
            </Link>
          </div>
          {offer.isPaused ? (
            <div className='tooltip' data-tip='Запустить'>
              <button
                onClick={() => changeStatus(offer.id, !offer.isPaused)}
                className='btn btn-accent btn-sm lowercase'
              >
                <PlayIcon />
              </button>
            </div>
          ) : (
            <div className='tooltip' data-tip='Остановить'>
              <button
                onClick={() => changeStatus(offer.id, !offer.isPaused)}
                className='btn btn-warning btn-sm lowercase'
              >
                <PauseIcon />
              </button>
            </div>
          )}

          <div className='tooltip' data-tip='Удалить'>
            <button
              onClick={removeHandler}
              className='btn btn-error btn-sm lowercase'
            >
              <TrashIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
