import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { NotificationManager as notify } from "react-notifications";

import { app, toggleAddCityModal } from "@/redux/features/appSlice";
import { useAddCityMutation } from "@/redux/api/citiesAPI";
import { IErrorResponse } from "@/types";

export const AddCityModal = () => {
  const { addCityModalIsOpen } = useAppSelector(app);
  const [addCity, { error, isSuccess }] = useAddCityMutation();
  const dispatch = useAppDispatch();

  const [cityName, setCityName] = useState("");

  const closeModalHandler = () => {
    dispatch(toggleAddCityModal());
    setTimeout(cleanForm, 500);
  };

  const cleanForm = () => {
    setCityName("");
  };

  const submitHandler = () => {
    if (!cityName) return notify.error("Введите название города");
    addCity({ name: cityName });
  };

  useEffect(() => {
    if (!error) return;
    const { data } = error as IErrorResponse;
    notify.error(data.message);
  }, [error]);

  useEffect(() => {
    if (!isSuccess) return;
    dispatch(toggleAddCityModal());
    setTimeout(cleanForm, 500);
    notify.success("Город добавлен!");
  }, [isSuccess, dispatch]);

  return (
    <div className={`modal ${addCityModalIsOpen ? "modal-open" : ""} `}>
      <div className="modal-box w-11/12  max-w-xl relative">
        <h3 className="font-bold text-2xl mb-5">Добавить город</h3>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Название города</span>
          </label>
          <input
            type="text"
            placeholder="Введите название"
            className="input input-bordered"
            value={cityName}
            onChange={(e) => setCityName(e.target.value)}
          />
        </div>

        <div className="modal-action">
          <button className="btn btn-sm btn-success" onClick={submitHandler}>
            Добавить
          </button>
          <button className="btn btn-sm btn-error" onClick={closeModalHandler}>
            Закрыть
          </button>
        </div>
      </div>
    </div>
  );
};
