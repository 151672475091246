import { Link } from "react-router-dom";

export const AddNews = () => {
  return (
    <div className="bg-base-100 shadow theme-border rounded-md px-6 py-4">
      <div className="opacity-60">Добавить Новость</div>
      <Link
        className="btn btn-success btn-sm mt-2 w-full md:w-auto"
        to="/cab/add-news"
      >
        Добавить
      </Link>
    </div>
  );
};
